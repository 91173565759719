import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import PrivacyNotice from '../components/PrivacyNotice';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
//import LoginPage from '../pages/login';

export default function PrivacyPolicyPage({location ,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
         <SEO
          data={data?.contentfulPrivacyPolicy?.seoEntry}
          siteurl = {data?.contentfulPrivacyPolicy?.slug}
          bucketName = {pageContext?.appData?.adminDetails?.s3BucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />

        <PrivacyNotice data={data?.contentfulPrivacyPolicy}/>
      </Layout>
    </>
  );
}
export const query = graphql`
  query privacyPolicyQuery($contentful_id: String,$locale: String) {
    contentfulPrivacyPolicy(contentful_id: { eq: $contentful_id },node_locale: { eq: $locale }){
        header
        lastPublicationText
        dateOfModification(formatString: "DD MMM YYYY")
        privacyPolicyContent {
          raw
        }
        slug
        seoEntry {
          altimageTag
          defaultOgLocale
          metaKeywords
          metaRobotsTag
          ogImage {
            file {
              url
            }
          }
          ogSiteName
          ogType
          pageTitle
          metaDescription {
            metaDescription
          }
        }
      }
  }
`;
