import React from 'react'
import './PrivacyNotice.scss'
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Container from '@material-ui/core/Container';

export default function PrivacyNotice({ data }) {
  const { header, lastPublicationText, dateOfModification, privacyPolicyContent } = data;
  const options = {
    renderNode: {
      [BLOCKS.TABLE]: (node, children) => (
        <div className='table-responsive'>
        <table className='privacy-table'>
          <tbody>{children}</tbody>
        </table>
        </div>
      ),
      [BLOCKS.TABLE_ROW]: (node, children) => <tr className='privacy-table-row'>{children}</tr>,
      [BLOCKS.TABLE_CELL]: (node, children) => <td className='privacy-table-data'>{children}</td>,
      [BLOCKS.HEADING_5]: (node, children) => <h3 className='para-title'>{children}</h3>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className='bullet-list'>{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [INLINES.HYPERLINK]: (node, children) => {
        return <a target="_blank" href={node.data.uri}>{children}</a>
      }
    },
  }
  return (
    <>
      <Container maxWidth="xl" className='privacy-content-container-div'>
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="privacy-notice-cls">
          <Grid item xl={1} lg={1} className="hide">&nbsp;</Grid>
          <Grid item xl={7} lg={8} md={12} sm={12} xs={12} className="border">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <h1 className='header-title'>{header}</h1>
              <h2 className='sub-title'>{lastPublicationText} {dateOfModification}</h2>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <p className='paragraph'>{documentToReactComponents(JSON.parse(privacyPolicyContent?.raw), options)}</p>
            </Grid>
          </Grid>
          <Grid item xl={4} lg={3} className="hide">&nbsp;</Grid>
        </Grid>
      </Container>
    </>
  )
}